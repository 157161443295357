/*
* 重写this.$message, 防止连续点击多次弹窗
*
* */
import { Message } from 'element-ui';
const showMessage = Symbol('showMessage');
class DonMessage {
  success(options, single = true) {
    this[showMessage]('success', options, single);
  }
  warning(options, single = true) {
    this[showMessage]('warning', options, single);
  }
  info(options, single = true) {
    this[showMessage]('info', options, single);
  }
  error(options, single = true) {
    this[showMessage]('error', options, single);
  }

  [showMessage](type, options, single) {
    if (single) {
      // 判断是否已存在Message
      if (document.getElementsByClassName('el-message').length === 0) {
        // 修改参数
        if (type === 'error' || type === 'warning') {
          options = {
            message: options,
            duration: 2000,
            showClose: false
          };
        } else {
          options = {
            message: options,
            duration: 2000,
            showClose: false
          };
        }
        Message[type](options);
      }
    } else {
      options = {
        message: options,
        duration: 2000,
        showClose: false
      };
      Message[type](options);
    }
  }
}
export const newMessage = new DonMessage();
