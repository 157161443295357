<template>
  <div class="downCenter">
    <!-- PC端 -->
    <div class="donwload-box-pc">
      <div class="item">
        <p class="item-title">
          教师客户端下载
        </p>
        <div class="item-detail">
          <img src="../../assets/images/edu/icon-donwload-teacher.png" class="imgDonw">
          <div class="remark">
            <p>
              点击“立即下载”按钮，可下载优智多趣味教学平台教师客户端。
            </p>
            <el-button type="primary" round class="btn" @click="donwLoadApp(donwloadInfo.teacherTerminalUrl)">
              立即下载
            </el-button>
          </div>
        </div>
      </div>
      <div class="item">
        <p class="item-title">
          学生客户端下载
        </p>
        <div class="item-detail">
          <img src="../../assets/images/edu/icon-donwload-student.png" class="imgDonw">
          <div class="remark">
            <p>
              点击“立即下载”按钮下载优智多趣味教学平台学生客户端，并在学生端进行安装。
            </p>
            <el-button type="primary" round class="btn" @click="donwLoadApp(donwloadInfo.studentTerminalUrl)">
              立即下载
            </el-button>
          </div>
        </div>
      </div>
      <div class="item">
        <p class="item-title">
          小程序
        </p>
        <div class="app-box">
          <div class="app-item">
            <div class="box">
              <div class="qr-box">
                <img src="../../assets/images/edu/icon-patriarch.jpg" class="app-imgDonw">
                <p class="app-name">
                  优智多趣味教学
                </p>
              </div>
              <div class="app-remark">
                教师打开微信扫一扫上方二维码，即可打开教师端小程序
              </div>
            </div>
          </div>
          <div class="app-item">
            <div class="box">
              <div class="qr-box">
                <img src="../../assets/images/edu/icon-teacher.jpg" class="app-imgDonw">
                <p class="app-name">
                  优智多家长端
                </p>
              </div>
              <div class="app-remark">
                家长打开微信扫一扫上方二维码，即可打开家长端小程序
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <p class="item-title">
          公众号
        </p>
        <div class="public-box">
          <div class="public-qr">
            <div class="qr-box">
              <img src="../../assets/images/index/publicQr.png" class="app-imgDonw">
              <p class="app-name">
                优智多教育
              </p>
            </div>
            <div class="app-remark">
              打开微信扫一扫上方二维码，关注优智多教育公众号，即可浏览公众号内容
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- mobile -->
    <div class="donwload-box-mobile">
      <div class="appbox-item">
        <div class="item-top">
          <img src="../../assets/images/edu/icon-donwload-teacher.png" class="mobileImgDonw">
          <div class="right">
            <p class="type-title">
              教师客户端下载
            </p>
            <el-button round type="primary" :data-clipboard-text="donwloadInfo.teacherTerminalUrl" class="clipboardName" @click="copy('.clipboardName')">
              点击复制
            </el-button>
          </div>
        </div>
        <div class="item-bottom">
          请将该地址复制到电脑浏览器上进行下载{{ donwloadInfo.teacherTerminalUrl }}
        </div>
      </div>
      <div class="appbox-item">
        <div class="item-top">
          <img src="../../assets/images/edu/icon-donwload-student.png" class="mobileImgDonw">
          <div class="right">
            <p class="type-title">
              学生客端下载
            </p>
            <el-button round type="primary" :data-clipboard-text="donwloadInfo.studentTerminalUrl" class="clipboardName" @click="copy('.clipboardName')">
              点击复制
            </el-button>
          </div>
        </div>
        <div class="item-bottom">
          请将该地址复制到电脑浏览器上进行下载并在学生端进行安装{{ donwloadInfo.studentTerminalUrl }}
        </div>
      </div>
      <div class="appbox-item  applet-box">
        <p class="qjx-title">
          小程序
        </p>
        <div class="qjxAPP-box">
          <div class="box">
            <div class="img-box">
              <img src="../../assets/images/edu/icon-patriarch.jpg" class="qrImgBox">
              <p>优智多趣味教学</p>
            </div>
            <div class="desc">
              教师打开微信扫一扫上方二维码即可打开教师端小程序
            </div>
          </div>
        </div>
        <div class="qjxAPP-box">
          <div class="box">
            <div class="img-box">
              <img src="../../assets/images/edu/icon-teacher.jpg" class="qrImgBox">
              <p>优质多家长端</p>
            </div>
            <div class="desc">
              家长打开微信扫一扫上方二维码即可打开家长端小程序
            </div>
          </div>
        </div>
      </div>
      <div class="appbox-item applet-box">
        <p class="qjx-title">
          公众号
        </p>
        <div class="qjxAPP-box">
          <div class="box">
            <div class="img-box">
              <img src="../../assets/images/index/publicQr.png" class="qrImgBox">
              <p>优智多趣味教学</p>
            </div>
            <div class="desc">
              扫一扫上方二维码，关注优智多教育公众号
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { donwloadPakge } from '@/api/edu.js';
import ClipboardJS from 'clipboard';
import { newMessage } from '@/utils/resetMessage';
export default {
  name: 'DownCenter',
  data() {
    return {
      donwloadInfo: {}
    };
  },
  mounted() {
    this.donwloadPakge();
  },
  methods: {
    // 复制功能
    copy(el) {
      var clipboard = new ClipboardJS(el);
      clipboard.on('success', () => {
        // this.$message.success('复制成功');
        newMessage.success(`复制成功`);
        clipboard.on('destory');
      });
      clipboard.on('error', () => {
        clipboard.on('destory');
      });
    },
    donwloadPakge() {
      donwloadPakge().then(res => {
        this.donwloadInfo = res.data;
      });
    },
    donwLoadApp(url) {
      if (url) {
        window.location.href = url;
      }
    }
  }
};
</script>
<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .downCenter{
    .donwload-box-mobile{
      display: none;
    }
    .donwload-box-pc{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 1200px;
      margin: 50px auto 20px;
      .item{
        background: #fff;
        border-radius: 10px;
        width: 48%;
        padding: 40px 50px;
        margin-bottom: 40px;
        .item-title{
          font-size: 20px;
          font-weight: bold;
          color: #333333;
          margin-bottom: 30px;
        }
        //app
        .item-detail{
          display: flex;
          .remark{
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
            .btn{
              margin-top: 20px;
            }
          }
          .imgDonw{
            width: 90px;
            height: 90px;
            margin-right: 20px;
          }
        }
        .app-imgDonw{
          width: 130px;
          height: 130px;
        }
        //公众号
        .app-box{
          display: flex;
          justify-content: space-around;
          padding-bottom: 60px;
        }
        .public-box{
          // display: flex;
          // justify-content: center;
          text-align: center;
          .public-qr{
            position: relative;
            .qr-box{
              position:absolute;
              left:35%;
              width: 170px;
              height: 190px;
              text-align: center;
            }
            .app-remark{
              padding-top: 205px;
              text-align: center;
              width: 100%;
            }
          }
        }
        .app-item{
          .box{
            width: 175px;
            height: 190px;
            text-align: center;
          }
        }
        .app-remark{
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 22px;
          margin-top: 15px;
          text-align: left;
          width: 190px;
        }
        .qr-box{
          border: 1px solid #EEEEEE;
          border-radius: 10px;
          padding: 20px 0px;
          p{
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            margin-top: 5px;
          }
        }
      }
    }
  }
}
@media screen and (min-width:992px) and (max-width: 1200px){
  .applet-box{
    display: flex;
    justify-content: center;
  }
  .appbox-item{
    .qjxAPP-box{
      text-align: center;
      width: 40%;
    }
  }
}
@media screen and (max-width: 1200px) {
  .donwload-box-pc{
    display: none;
  }
  .donwload-box-mobile{
    padding: 15px;
    .appbox-item{
      background: #fff;
      border-radius: 10px;
      padding: 20px;
      margin-bottom: 15px;
      .item-top{
        display: flex;
        align-items: center;
        .mobileImgDonw{
          width: 90px;
          height: 90px;
        }
        .right{
          margin-left: 20px;
          .type-title{
            font-size: 20px;
            font-weight: bold;
            color: #333333;
            margin-bottom: 18px;
          }
        }
      }
      .item-bottom{
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        line-height: 18px;
        margin-top: 16px;
      }
      .qjx-title{
        font-size: 20px;
        font-weight: bold;
        color: #333333;
      }
      .qjxAPP-box{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        .box{
          text-align: center;
          width: 180px;
        }
        .img-box{
          border-radius: 10px;
          border: 1px solid #EEEEEE;
          width: 170px;
          padding: 12px 0 18px;
          .qrImgBox{
            width: 130px;
            height: 130px;
          }
          p{
            margin-top: 5px;
          }
        }
        .desc{
          font-size: 13px;
          font-weight: 400;
          color: #333333;
          margin-top: 16px;
        }
      }
    }
  }
}
</style>
